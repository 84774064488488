import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import {
  Button,
  Typography,
  ThemeProvider,
  TextField,
  Theme,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import CommonHeader from '../parts/CommonHeader'
import SettingSelector from '../parts/SettingSelector'
import MarkerDragOnMap from '../parts/MarkerDragOnMap'
import Book from '../../models/Book'

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const MapSettingScreen: React.FC<any> = (props: Props) => {
  const classes = useStyles(theme);
  return (
    <div>
      <CommonHeader />
      <SettingSelector
        bookId={ props.book.get('book_id') }
        currentIndex={1}
      />
      <ThemeProvider theme={theme}>
        <Typography variant="h3" align="center">
          マップ設定
        </Typography>
      </ThemeProvider>
      <MarkerDragOnMap
        stampCollection={ props.book.get('stamps') }
        currentStamp={ null }
        mapImgContent={ props.book.get('map_path') }
        dragStopCallback={ () => {} }
        onSelectStamp={ () => {} }
        isDraggable={ false }
      />
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={ () => {
            props.goToMain()
          }}
        >
          戻る
        </Button>
      </div>
    </div>
  )
}

type Props = {
  goToMain: any,
  book: Book
};

const mapStateToProps = (state: AppState, ownProps):any => {
  const { id } = ownProps.match.params
  return {
    book: state.books.getBookById(id),
  }
}

const mapDispatchToProps = (dispatch: Dispatch ): any => {
  return {
    goToMain: () => dispatch(push('/')),
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      maxWidth: 900,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttons: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    button: {
      textTransform: 'none',
      width: '40%'
    }
  })
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapSettingScreen)
