import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware, RouterState, } from 'connected-react-router'
import { bookCollectionReducer } from './reducers/BookCollectionReducer'
import { bookCreateSessionReducer } from './reducers/BookCreateSessionReducer'
import BookCollection from './models/BookCollection'
import BookCreateSession from './models/BookCreateSession'
import { createBrowserHistory } from "history"

export const history = createBrowserHistory()

export type AppState = {
  router: RouterState,
  books: BookCollection,
  bookCreateSession: BookCreateSession
};


export default createStore(
  combineReducers<AppState>({
    router: connectRouter(history),
    books: bookCollectionReducer,
    bookCreateSession: bookCreateSessionReducer,
  }),
  compose(
    applyMiddleware(
      routerMiddleware(history)
    )
  )
);
