import { Record, List } from 'immutable'
import Book from './Book'
import Stamp from './Stamp'

interface bookCollectionState {
  books: List<Book>;
}

const books : List<Book> = List([])

const initialState : bookCollectionState = {
  books: books
}

const BookCollectionRecord = Record(initialState)

export default class BookCollection extends BookCollectionRecord {
  constructor(books? : List<Book>) {
    super({books: books})
  }
  getBooks(): List<Book> {
    return this.get('books')
  }
  getBookById(id: string): Book|undefined {
    return this.get('books').find((book: Book) => (book.get('book_id') === id))
  }
  getStampByIdAndStampId(id: string, stampId: number): Stamp|undefined {
    const book = this.getBookById(id)
    if (book) {
      return book.get('stamps').find((stamp: Stamp) => (stamp.get('stamp_id') === stampId))
    }
    return undefined
  }
  setBooksFromApi(books: Array<any>) {
    const bookObjList:Array<Book> = []
    books.map(book => {
      const stamps:Array<any> = book.stamps
      const stampObjList: Array<Stamp> = []
      stamps.map(stamp => {
        const stampObj = new Stamp({
          stamp_id: stamp.id,
          title: stamp.title,
          description: stamp.description,
          icon_path: stamp.icon_path,
          not_found_path: stamp.not_found_path,
          fbx_path: stamp.fbx_path,
          position: stamp.position,
        })
        stampObjList.push(stampObj)
      })
      const bookObj = new Book({
        book_id: book.id,
        title: book.title,
        description: book.description,
        icon_path: book.icon_path,
        map_path: book.map_path,
        created_at: book.created_at,
        updated_at: book.updated_at,
        stamps: List(stampObjList)
      })
      bookObjList.push(bookObj)
    })
    const bookList = List(bookObjList)
    return this.set('books', bookList)
  }
}
