import actionCreatorFactory from 'typescript-fsa';
import { List } from 'immutable';
import BookCreateStampSession from '../models/BookCreateStampSession';


const actionCreator = actionCreatorFactory();

export const registBookOutline = actionCreator<any>('REGIST_BOOK_OUTLINE');
export const setMode = actionCreator<number>('SET_MODE');
export const setMapFile = actionCreator<File>('SET_MAP_FILE');
export const setStampSessionList = actionCreator<List<BookCreateStampSession>>('SET_STAMP_SESSION_LIST');
export const updateStampPosition = actionCreator<any>('UPDATE_STAMP_POSITION')
