import React from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import firebaseApp from './Firebase'

const Auth: React.FC<any> = (props:Props) => {
  return (
    firebaseApp.auth().currentUser ?
      props.children :
      <Redirect to={'/sign_in'}/>
  )
}

type Props = {
  children: any,
}

export default connect(
  null,
  null,
)(Auth)
