import React from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  Theme,
  makeStyles,
  createStyles, Button, Collapse
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {useState} from 'react';
import firebaseApp from "../../Firebase";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {Dispatch} from "redux";
import {push} from "connected-react-router";

const CommonHeader: React.FC<any> = (props: Props) => {
  const classes = useStyles();
  const [visibleLogout, setVisibleLogout] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <Toolbar variant="dense">
          <IconButton size={'small'} onClick={() => {
            setVisibleLogout(value => !value);
          }}>
            <ExitToAppIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Collapse
        style={{width: 400}}
        in={visibleLogout}>
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small"
                    variant="outlined"
                    onClick={async () => {
                      setVisibleLogout(false);
                      await firebaseApp.auth().signOut().catch(error => {
                        console.log(error.code);
                        console.log(error.message);
                      });
                      props.goToTop();
                    }}>
              実行
            </Button>
          }
        >
          <AlertTitle>ログアウト</AlertTitle>
          ログアウトしますか？
        </Alert>
      </Collapse>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: 10,
    },
    title: {
      position: 'relative',
      flexGrow: 1,
      left: '50%',
      transform: 'translate(-50%, 0%)'
    }
  })
)

type Props = {
  goToTop: any,
}

const mapStateToProps = (state: AppState): any => {
  return {}
}

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return {
    goToTop: () => dispatch(push('/sign_in')),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonHeader)
