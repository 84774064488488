import firebaseApp from './Firebase'
import BookCreateSession from './models/BookCreateSession'

const getPubApiBaseUrl = (): string => {
  return `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/app/pub/`;
  // TODO local debug用(.envで吸収するようにしたい)
  //return 'http://localhost:5000/dena-dgt-stampcollect-dev-gcp/asia-northeast1/app/pub/';
}

const getOpeApiBaseUrl = (): string => {
  return `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/app/ope/`;
  // TODO local debug用(.envで吸収するようにしたい)
  //return 'http://localhost:5000/dena-dgt-stampcollect-dev-gcp/asia-northeast1/app/ope/';
}

export const getBooks = async () => {
  return await getWithAuth((getOpeApiBaseUrl() + 'books'));
}

export const createBook = async (bookCreateSession: BookCreateSession) => {
  var formData = new FormData()
  // book情報
  formData.append('title', bookCreateSession.get('title'))
  formData.append('description', bookCreateSession.get('description'))
  formData.append('icon', bookCreateSession.get('iconFile'))
  formData.append('map', bookCreateSession.get('mapFile'))
  // stamp情報
  bookCreateSession.get('stampSessionList').map((stampSession, index) => {
    formData.append(('stamp_title_' + (index + 1)), stampSession.get('title'))
    formData.append(('stamp_description_' + (index + 1)), stampSession.get('description'))
    // NOTE : fileListはmapが使えないので仕方なくfor文を使う
    for (var index2 = 0; index2 < stampSession.get('ar_file_list').length; ++index2) {
      formData.append(('stamp_ar_list_' + (index + 1)), stampSession.get('ar_file_list')[index2])
    }
    formData.append(('stamp_not_found_' + (index + 1)), stampSession.get('not_found_file'))
    formData.append(('stamp_position_x_' + (index + 1)), stampSession.get('pos_x').toString())
    formData.append(('stamp_position_y_' + (index + 1)), stampSession.get('pos_y').toString())
  })
  const resultJson = await postWithAuth((getOpeApiBaseUrl() + 'book/create'), formData)
  return resultJson
}

const getWithAuth = async (url: string) => {
  const currentUser = firebaseApp.auth().currentUser;
  // Auth.tsxで基本的に弾いているのでないはず
  if (!currentUser) {
    return;
  }
  console.log('url : ' + url)
  const idToken = await currentUser.getIdToken()
  const result = await fetch(url, {
    headers: new Headers({
      'Authorization': idToken,
    }),
    mode: 'cors',
    credentials: 'include'
  })
  const resultJson = await result.json();
  console.log('resultJson')
  console.log(resultJson);
  return resultJson
}

const postWithAuth = async (url: string, formData: FormData) => {
  const currentUser = firebaseApp.auth().currentUser;
  // Auth.tsxで基本的に弾いているのでないはず
  if (!currentUser) {
    return;
  }
  console.log('url : ' + url)
  const idToken = await currentUser.getIdToken()
  const result = await fetch(url, {
    headers: new Headers({
      'Authorization': idToken,
    }),
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
    body: formData
  })
  return await result.json();
}

export const getCustomToken = async (userEmail: string, userPassword: string) => {
  try {
    const result = await fetch(getPubApiBaseUrl() + 'customToken', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({
        'user_email': userEmail,
        'user_password': userPassword
      })
    }).catch((error) => {
      console.log(error)
      throw Error(error);
    })
    return await result.json();
  } catch (error) {
    console.log(error)
    throw Error(error);
  }
}
