import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  Button,
  TextField,
  Theme,
  Typography,
  ThemeProvider,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { push } from 'connected-react-router'
import firebaseApp from '../../Firebase'
import CommonHeader from '../parts/CommonHeader'

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const SignInScreen: React.FC<any> = (props: Props) => {
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const classes = useStyles(theme);

  const onChangeEmail = (event: any) => {
    setUserEmail(event.target.value)
  }
  const onChangePassword = (event: any) => {
    setUserPassword(event.target.value)
  }

  return (
    <div>
      <CommonHeader />
      <ThemeProvider theme={theme}>
        <Typography variant="h3" align="center">
          アカウントを新規作成
        </Typography>
      </ThemeProvider>
      <div className={ classes.content}>
        Email:
        <TextField
          className={ classes.contentFull }
          name="email"
          size="small"
          variant="outlined"
          value={userEmail}
          onChange = { onChangeEmail }
        />
        Password:
        <TextField
          className={ classes.contentFull }
          name="password"
          size="small"
          variant="outlined"
          value={userPassword}
          onChange = { onChangePassword }
        />
        <Button className={classes.contentFull + " " + classes.button} variant="contained" color="primary" onClick={ async () => {
          console.log(userEmail)
          console.log(userPassword)
          // TODO : hirohashi - 失敗することもあるのでエラーハンドリングする
          await firebaseApp.auth().createUserWithEmailAndPassword(userEmail, userPassword)
          props.goToMain()
        }}>
          登録
        </Button>
        <Button className={classes.contentFull + " " + classes.button} variant="contained" color="primary">
          Facebookで登録
        </Button>
        <Button className={classes.contentFull + " " + classes.button} variant="contained" color="primary"　onClick={ () => {
          props.goToSignIn()
        }}>
          戻る
        </Button>
      </div>
    </div>
  );
}

type Props = {
  setCurrentUser: any,
  goToMain: any,
  goToSignIn: any,
  name: string
};

const mapDispatchToProps = (dispatch: Dispatch ): any => {
  return {
    goToMain: () => dispatch(push('/')),
    goToSignIn: () => dispatch(push('/sign_in')),

  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      maxWidth: 640,
      margin: '0 auto'
    },
    contentFull: {
      margin: 10,
      width: '95%',
    },
    button: {
      textTransform: 'none',
    }
  })
)

export default connect(
  null,
  mapDispatchToProps
)(SignInScreen)
