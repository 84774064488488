import React from 'react';
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import Auth from './Auth'
import SignInScreen from './components/screen/SignInScreen'
import SignUpScreen from './components/screen/SignUpScreen'
import MainScreen from './components/screen/MainScreen'
import BookSettingScreen from './components/screen/BookSettingScreen'
import MapSettingScreen from './components/screen/MapSettingScreen'

import store, { history } from './store'

const App: React.FC = () => {
  return (
    <Provider store={ store }>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/sign_in" component={SignInScreen} />
          <Route path="/sign_up" component={SignUpScreen} />
          <Auth>
            <Switch>
              <Route exact path="/" component={MainScreen} />
              <Route path="/book_setting/:id" component={BookSettingScreen} />
              <Route path="/map_setting/:id" component={MapSettingScreen} />
            </Switch>
          </Auth>
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
