import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  Button,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { push } from 'connected-react-router'

function getSettings(): Array<string> {
  return [
    'ブック設定',
    'マップ設定',
    'スタンプ設定',
    'お知らせ設定',
  ];
}

const baseUrlsForSettings = [
  '/book_setting/',
  '/map_setting/',
  '/stamp_setting',
  '/notification_setting/'
]

function getUrlByIndexAndBookId(index: number, bookId: string): string {
  const baseUrl = baseUrlsForSettings[index]
  const url = baseUrl + bookId
  return url
}

const SettingSelector: React.FC<Props> = (props: Props) => {

  const settings = getSettings();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {settings.map((setting, index) => {
        return (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={ () => {
              const url = getUrlByIndexAndBookId(index, props.bookId)
              props.goToForSetting(url)
            }}
            disabled={ (props.currentIndex === index) ? true : false}
          >
            { setting }
          </Button>
        )
      })}
    </div>
  );
}

type Props = {
  bookId: string,
  currentIndex: number,
  goToForSetting?: any
};

const mapDispatchToProps = (dispatch: Dispatch ): any => {
  return {
    goToForSetting: (settingUrl: string) => dispatch(push(settingUrl))
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '0 auto',
      width: 900,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItem: 'center'
    },
    button: {
      margin: 10,
      textTransform: 'none',
      width: '20%',
    }
  })
)

export default connect(
  null,
  mapDispatchToProps
)(SettingSelector)
