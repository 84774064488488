import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import {
  Button,
  Typography,
  ThemeProvider,
  TextField,
  Theme,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import CommonHeader from '../parts/CommonHeader'
import SettingSelector from '../parts/SettingSelector'
import Book from '../../models/Book'

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const BookSettingScreen: React.FC<any> = (props: Props) => {
  const classes = useStyles(theme);
  return (
    <div>
      <CommonHeader />
      <SettingSelector
        bookId={ props.book.get('book_id') }
        currentIndex={0}
      />
      <ThemeProvider theme={theme}>
        <Typography variant="h3" align="center">
          ブック設定
        </Typography>
      </ThemeProvider>
      <div className={ classes.content }>
        <div className={ classes.subTitle }>ブック名を入力してください</div>
        <TextField
          className={ classes.contentFull }
          name="bookTitle"
          size="small"
          variant="outlined"
          value={ props.book.get('title') }
        />
        <div className={ classes.subTitle }>ブックの説明を記入してください</div>
        <TextField
          className={ classes.contentFull }
          rows="6"
          multiline
          name="bookDescription"
          size="small"
          variant="outlined"
          value={ props.book.get('description') }
        />
        <div className={ classes.subTitle }>ブックのアイコンを記入してください</div>
        <div className={ classes.bookIconImgArea}>
          <img
            src={ props.book.get('icon_path') }
            className={ classes.bookIconImg }
          />
        </div>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={ () => {
              props.goToMain()
            }}
          >
            戻る
          </Button>
        </div>
      </div>
    </div>
  )
}

type Props = {
  goToMain: any,
  registBookOutline: any,
  book: Book
};

const mapStateToProps = (state: AppState, ownProps):any => {
  const { id } = ownProps.match.params
  return {
    book: state.books.getBookById(id),
  }
}

const mapDispatchToProps = (dispatch: Dispatch ): any => {
  return {
    goToMain: () => dispatch(push('/')),
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      maxWidth: 640,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    subTitle: {
      width: '100%',
      textAlign: 'center'
    },
    contentFull: {
      marginTop: 10,
      marginBottom: 30,
      width: '95%',
    },
    bookIconImgArea: {
      width: 150,
      height: 150,
      padding: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bookIconImg: {
      width: '100%',
      height: '100%'
    },
    buttons: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    button: {
      textTransform: 'none',
      width: '40%'
    }
  })
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookSettingScreen)
