import { Record, List } from 'immutable'
import Stamp from './Stamp'
import { ASSET_BASE_URL } from '../const'

interface bookState {
  book_id: string;
  title: string;
  description: string;
  icon_path: string;
  map_path: string;
  created_at: number;
  updated_at: number;
  stamps: List<Stamp>;
}

const initialState: bookState = {
  book_id: '',
  title: '',
  description: '',
  icon_path: '',
  map_path: '',
  created_at: 0,
  updated_at: 0,
  stamps: List([])
}

const BookRecord = Record(initialState)

export default class Book extends BookRecord {
  getIconUrl() : string {
    return ASSET_BASE_URL + 'books/' + this.get('book_id') + '/icon.png'
  }
  getMapUrl() : string {
    return ASSET_BASE_URL + 'books/' + this.get('book_id') + '/map.png'
  }
  getTotalStampNum(): number {
    return this.get('stamps').count()
  }
}
