import { List, Record } from 'immutable'
import BookCreateStampSession from './BookCreateStampSession'

export enum Mode {
  AR = 0,
  Camera,
  QR
}

interface bookCreateSessionState {
  title: string;
  description: string;
  iconFile: File|null;
  mode: Mode;
  mapFile: File|null;
  stampSessionList: List<BookCreateStampSession>;
}

const stampSessionList: List<BookCreateStampSession> = List([ new BookCreateStampSession() ])

const initialState: bookCreateSessionState = {
  title: '',
  description: '',
  iconFile: null,
  mode: Mode.AR,
  mapFile: null,
  stampSessionList: stampSessionList
}

const BookCreateSessionRecord = Record(initialState)

export default class BookCreateSession extends BookCreateSessionRecord {
  updateStampPositionByIndexAndPosition (index: number, pos_x: number, pos_y: number) {
    var stampSessionList = this.get('stampSessionList')
    stampSessionList = stampSessionList.setIn([index, "pos_x"], pos_x)
    stampSessionList = stampSessionList.setIn([index, "pos_y"], pos_y)
    return this.set("stampSessionList", stampSessionList)
  }
}
