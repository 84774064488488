import { Record } from 'immutable'
import { StampInterface } from './StampInterface'
import { ASSET_BASE_URL } from '../const'

interface stampState {
  stamp_id: number;
  title: string;
  description: string;
  icon_path: string;
  not_found_path: string;
  fbx_path: string;
  position: any;
}

const initialState: stampState = {
  stamp_id: 0,
  title: '',
  description: '',
  icon_path: '',
  not_found_path: '',
  fbx_path: '',
  position: { x: 0, y: 0 },
}

const StampRecord = Record(initialState)

export default class Stamp extends StampRecord implements StampInterface {
  getIconUrl(bookId: number): string {
    const url = ASSET_BASE_URL + 'books/' + bookId + '/stamps/' + this.get('stamp_id') + '.png'
    return url
  }
  getPositionX(): number {
    return this.get('position').x
  }
  getPositionY(): number {
    return this.get('position').y
  }
  getTitle(): string {
    return this.get('title')
  }
}
