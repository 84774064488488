import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { fetchAllData } from '../actions/BookCollectionActions'
import BookCollection from '../models/BookCollection'

const initialState: BookCollection = new BookCollection();

export const bookCollectionReducer = reducerWithInitialState(initialState)
  .case(fetchAllData, (state: BookCollection, books: Array<any>) => {
    return state.setBooksFromApi(books)
  })
