import React from 'react'
import { List } from 'immutable'
import {
  Theme,
  makeStyles,
  createStyles
} from '@material-ui/core'
import Draggable from 'react-draggable'
import { StampInterface } from '../../models/StampInterface'

const MarkerDragOnMap: React.FC<any> = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={ classes.mapAndStampList }>
      <div className={ classes.mapImageArea }>
        <img
          src={props.mapImgContent}
          className={classes.mapImage}
          draggable={false}
        />
        {
          props.stampCollection.map((stamp, index) => {
            return (
              <Draggable
                defaultPosition={{x: stamp.getPositionX(), y: stamp.getPositionY()}}
                bounds="parent"
                onStart={ () => {
                  if (!props.isDraggable) {
                    return false
                  }
                  if (!props.currentStamp || props.currentStamp.get('title') != stamp.getTitle()) {
                    return false
                  }
                }}
                onStop={ (_e, position) => {
                  if (!props.isDraggable) {
                    return
                  }
                  const {x, y} = position
                  props.dragStopCallback({
                    pos_x: x,
                    pos_y: y,
                    index: index
                  })
                }}
              >
                <img
                  src={require('../../assets/marker.png')}
                  style={{width: 30, height: 45, position: 'absolute', left: 0, top: 0}}
                  draggable={false}
                />
              </Draggable>
            )
          })
        }
      </div>
      <div className={ classes.stampListArea }>
        {
          props.stampCollection.map((stamp, index) => {
            return (
              <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 5 }}
                onClick={ () => { props.onSelectStamp(stamp) }}
              >
                <img
                  src={require('../../assets/marker.png')}
                  style={{width: 30, height: 45}}
                  draggable={false}
                />
                <div className={ classes.stampTitle }>
                { stamp.getTitle() }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

type Props = {
  stampCollection: List<StampInterface>
  currentStamp: any,
  mapImgContent: any,
  dragStopCallback: any,
  onSelectStamp: any,
  isDraggable: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapAndStampList: {
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    mapImageArea: {
      width: 640,
      border: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    mapImage: {
      width: '100%',
    },
    stampListArea: {
      width: 200,
      borderStyle: 'solid',
      borderColor: 'black',
      borderRadius: 10,
    },
    stampTitle: {
      width: 155,
      height: 30,
      textAlign: 'center',
      borderStyle: 'solid',
      borderColor: '#F5F5F5',
      borderRadius: 10,
      backgroundColor: '#F5F5F5',
      lineHeight: '30px'
    }
  })
)

export default MarkerDragOnMap
