import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { List } from 'immutable'
import {
  registBookOutline,
  setMode,
  setMapFile,
  setStampSessionList,
  updateStampPosition
} from '../actions/BookCreateSessionActions'
import BookCreateSession from '../models/BookCreateSession'
import BookCreateStampSession from '../models/BookCreateStampSession'

const initialState: BookCreateSession = new BookCreateSession();

export const bookCreateSessionReducer = reducerWithInitialState(initialState)
  .case(registBookOutline, (state: BookCreateSession, basicSetting: any) => {
    return state.set('title', basicSetting.title).set('description', basicSetting.description).set('iconFile', basicSetting.iconFile)
  })
  .case(setMode, (state: BookCreateSession, mode: number) => {
    return state.set('mode', mode)
  })
  .case(setMapFile,(state: BookCreateSession, file:File) => {
    return state.set('mapFile', file)
  })
  .case(setStampSessionList,(state: BookCreateSession, stampSessionList: List<BookCreateStampSession>) => {
    return state.set('stampSessionList', stampSessionList)
  })
  .case(updateStampPosition, (state: BookCreateSession, updateInfo: any) => {
    return state.updateStampPositionByIndexAndPosition(updateInfo.index, updateInfo.pos_x, updateInfo.pos_y)
  })
