import React, {useEffect, useState} from 'react'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {AppState} from '../../store'
import {
  makeStyles,
  createStyles,
  Table,
  ThemeProvider,
  Typography,
  TextField,
  FormControl,
  Select,
  FormHelperText,
  InputLabel,
  MenuItem,
  createMuiTheme,
  responsiveFontSizes,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  Paper
} from '@material-ui/core'
import {push} from 'connected-react-router'
import CommonHeader from '../parts/CommonHeader'
import {fetchAllData} from '../../actions/BookCollectionActions'
import BookCollection from '../../models/BookCollection'
import firebaseApp from "../../Firebase";
import {getBooks} from "../../api";

const MainScreen: React.FC<any> = (props: Props) => {

  let theme = createMuiTheme();
  theme = responsiveFontSizes(theme);

  const [rewardCodeMap, setRewardCodeMap] = useState<{ [key: string]: string }>({});
  const [viewableRewardCodeCollection, setViewableRewardCodeCollection] = useState<{ rewardCode: string, match: boolean, key: number }[]>([]);

  const [bookMap, setBookMap] = React.useState<{ [key: string]: any }>({});
  const [bookId, setBookId] = React.useState('');

  const handleChangeBookId = (event) => {
    setBookId(event.target.value);
  };

  const [searchWords, setSearchWord] = useState<string>('');
  const onChangeSearchWord = (event: any) => {
    setSearchWord(event.target.value)
  }

  const formattedCollection = (_rewardCodeMap: { [key: string]: string }) => searchWords?.trim().split('\n')
    .filter((searchWord: string) => searchWord?.trim() !== '')
    .map((searchWord: string, key: number) => {
      const match = Object.keys(_rewardCodeMap).sort().some(userId => _rewardCodeMap[userId] === searchWord);
      return {rewardCode: searchWord, match: match, key: key};
    });

  useEffect(() => {
    if (searchWords === '') {
      setViewableRewardCodeCollection([]);
      return;
    }
    setViewableRewardCodeCollection(formattedCollection(rewardCodeMap));
  }, [searchWords]);

  useEffect(() => {
    (async () => {
      const _rewardCodeMap = await (async () => {
        if (Object.keys(bookMap).length < 1) {
          return {};
        }

        if (bookId === '') {
          return {};
        }
        const stampSum = bookMap[bookId]?.stamps?.length ?? 0;

        const rewardCodeCollection = await firebaseApp.firestore().collection('rewardCode').doc(bookId).get().catch(error => {
          console.log(error.code);
          console.log(error.message);
        });

        if (!rewardCodeCollection || !rewardCodeCollection.exists) {
          return {};
        }
        const rewardCodeData: { [key: string]: string } = rewardCodeCollection.data();

        const usersData = await firebaseApp.firestore().collection('users').get().catch(error => {
          console.log(error.code);
          console.log(error.message);
        });

        if (!usersData) {
          return {};
        }

        const validRewardCodeMap = {};
        usersData.forEach((doc: any) => {
          const userId = doc.id;
          const stamps = doc.data().books?.[bookId];
          if (!stamps) {
            return;
          }
          const stampCollectNum: number = Object.keys(stamps).length ?? 0;
          if (stampCollectNum < stampSum) {
            return;
          }
          if (!rewardCodeData[userId]) {
            return;
          }
          Object.assign(validRewardCodeMap, {[userId]: rewardCodeData[userId]});
        });
        return validRewardCodeMap;
      })();
      setRewardCodeMap(_rewardCodeMap);
      if (searchWords === '') {
        setViewableRewardCodeCollection([]);
        return;
      }
      setViewableRewardCodeCollection(formattedCollection(_rewardCodeMap));

    })();
  }, [bookId]);

  useEffect(() => {
    (async () => {

      const currentUser = firebaseApp.auth().currentUser;
      if (!currentUser) {
        return;
      }

      const books = await (async ()=>{
        const result = await getBooks().catch((error) => {
          console.log(error.message);
          return {books: {}};
        });
        return result.books ?? {};
      })();

      setBookMap(books);
      setBookId(Object.keys(books).length < 1 ? '' : Object.keys(books)[0]);
    })();
  }, [])

  const classes = useStyles();

  const bookListItems = Object.keys(bookMap)?.map((bookId: string) =>
    <MenuItem key={bookId} value={bookId}>{bookMap[bookId].title}</MenuItem>
  );

  const ListItem = (props) => {
    return (
      <TableRow className={classes.tableRow} key={props.value.key}>
        <TableCell style={{color: props.value.match ? 'green' : 'red'}}>{props.value.rewardCode}</TableCell>
        <TableCell>{props.value.match ? '○' : 'x'}</TableCell>
      </TableRow>
    );
  }
  const listItems = viewableRewardCodeCollection?.map((value, index) =>
    <ListItem key={index} value={value}/>
  );

  return (
    <div>
      <CommonHeader/>
      <ThemeProvider theme={theme}>
        <Typography variant="h3" align="center">
        </Typography>
      </ThemeProvider>
      <FormControl style={{margin: 20}}>
        <InputLabel id="demo-simple-select-helper-label">アルバム</InputLabel>
        <Select
          labelId="book-id-select-label"
          id="book-id-select-id"
          defaultValue={''}
          value={bookId}
          onChange={handleChangeBookId}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {bookListItems}
        </Select>
        <FormHelperText>アルバムを選択してください</FormHelperText>
      </FormControl>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
        <div className={classes.content}>
          <TextField
            className={classes.contentFull}
            name="searchWord"
            size="small"
            rows={Math.max(10, viewableRewardCodeCollection?.length)}
            variant="outlined"
            value={searchWords}
            placeholder={'応募コード(複数行可)'}
            multiline={true}
            onChange={onChangeSearchWord}
          />
        </div>
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="customized table" size="small">
            <TableHead style={{backgroundColor: '#3f51b5', color: '#ffffff'}}>
              <TableRow>
                <TableCell style={{color: 'inherit'}}>応募コード</TableCell>
                <TableCell style={{color: 'inherit'}}>判定</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listItems}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

type Props = {
  onPushCreateBook: any,
  onPushIcon: any,
  bookCollection: BookCollection,
  fetchAllData: any,
};

const mapStateToProps = (state: AppState): any => {
  return {
    bookCollection: state.books,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return {
    onPushCreateBook: () => dispatch(push('/create_book_step1')),
    onPushIcon: (bookId: string) => dispatch(push('/book_setting/' + bookId)),
    fetchAllData: (books: Array<any>) => dispatch(fetchAllData(books)),
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    books: {
      maxWidth: 900,
      margin: '0 auto',
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: 30,

      "&::after": {
        content: `""`,
        display: 'block',
        width: '30%'
      }
    },
    book: {
      width: '30%',
      height: 'auto',
      boxSizing: "border-box",
    },
    bookImg: {
      width: "100%",
      height: "auto",
    },
    content: {
      width: "95%",
      display: 'flex',
      flex: 1,
      margin: 10,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    table: {
      display: 'flex',
      flex: 2,
      margin: 10,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#eeeeee',
      },
    },
    contentFull: {
      width: '95%',
    },
  })
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainScreen)
