import { Record } from 'immutable'
import { StampInterface } from './StampInterface'
interface bookCreateStampSessionState {
  title: string;
  description: string;
  ar_file_list: FileList|null,
  not_found_file: File|null,
  pos_x: number,
  pos_y: number,
}

const initialState: bookCreateStampSessionState = {
  title: '',
  description: '',
  ar_file_list: null,
  not_found_file: null,
  pos_x: 0,
  pos_y: 0,
}

const BookCreateStampSessionRecord = Record(initialState)

export default class BookCreateStampSession extends BookCreateStampSessionRecord implements StampInterface {
  getPositionX(): number {
    return this.get('pos_x')
  }
  getPositionY(): number {
    return this.get('pos_y')
  }
  getTitle(): string {
    return this.get('title')
  }
}
