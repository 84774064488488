import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {
  Button,
  TextField,
  Theme,
  Typography,
  ThemeProvider,
  makeStyles,
  createStyles
} from '@material-ui/core'
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles'
import {push} from 'connected-react-router'
import firebaseApp from '../../Firebase'
import CommonHeader from '../parts/CommonHeader'
import firebase from 'firebase';
import {getCustomToken} from '../../api'

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const SignInScreen: React.FC<any> = (props: Props) => {
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles(theme);

  useEffect(() => {
    (async () => {
      const currentUser = await firebaseApp.auth().currentUser;
      if (!!currentUser) {
        props.goToMain()
      }
    })();

    return () => {

    };
  }, []);


  const onChangeEmail = (event: any) => {
    setUserEmail(event.target.value)
  }
  const onChangePassword = (event: any) => {
    setUserPassword(event.target.value)
  }

  return (
    <div>
      <CommonHeader/>
      <ThemeProvider theme={theme}>
        <Typography variant="h3" align="center">
        </Typography>
      </ThemeProvider>
      <div className={classes.content}>
        メールアドレス:
        <TextField
          className={classes.contentFull}
          name="email"
          size="small"
          variant="outlined"
          value={userEmail}
          onChange={onChangeEmail}
        />
        パスワード:
        <TextField
          className={classes.contentFull}
          name="password"
          size="small"
          variant="outlined"
          value={userPassword}
          onChange={onChangePassword}
        />
        <Button className={classes.contentFull + " " + classes.button} variant="contained" color="primary"
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  await firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).catch(error => {
                    console.log(error.code);
                    console.log(error.message);
                  });

                  const loginErrorMessage = 'ログイン情報が正しくありません';

                  const apiResult = await getCustomToken(userEmail, userPassword).catch((error) => {
                    console.log(error.message);
                  });
                  if (!apiResult) {
                    alert(loginErrorMessage);
                    setIsLoading(false);
                    return;
                  }
                  const result = await firebaseApp.auth().signInWithCustomToken(apiResult.token).catch(error => {
                    console.log(error.code);
                    console.log(error.message);
                  });
                  if (!result) {
                    alert(loginErrorMessage);
                    setIsLoading(false);
                    return;
                  }
                  setIsLoading(false);

                  props.goToMain()
                }}>
          {isLoading ? 'ログイン中' : 'ログイン'}
        </Button>
      </div>
    </div>
  );
}

type Props = {
  setCurrentUser: any,
  goToMain: any,
  goToSignUp: any,
  name: string
};

const mapDispatchToProps = (dispatch: Dispatch): any => {
  return {
    goToMain: () => dispatch(push('/')),
    goToSignUp: () => dispatch(push('/sign_up'))
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      maxWidth: 640,
      margin: '0 auto'
    },
    contentFull: {
      margin: 10,
      width: '95%',
    },
    button: {
      textTransform: 'none',
    }
  })
)

export default connect(
  null,
  mapDispatchToProps
)(SignInScreen)
